//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepLayout from './step-layout.vue';
import Button from '../components/button.vue';
import Radio from '../components/radio.vue';
import stepMixin from '../lib/step-mixin.js';

export default {
  components: {
    StepLayout,
    Button,
    Radio
  },
  mixins: [stepMixin],
  props: {
    leadId: {
      type: String,
      required: true
    },
    contractData: Object,
    formExternalData: Object,
    scoring: String,
    allSteps: Object
  },
  data: () => ({
    selected: '',
    loading: false
  }),
  computed: {
    phone() {
      return this.contractData?.contractInfo?.owner?.phone;
    },
    email() {
      return this.contractData?.contractInfo?.owner?.email;
    }
  },
  created() {},
  methods: {
    onContinue() {
      this.loading = true;
      this.$emit('continue', { channelId: this.selected });
    }
  }
};
